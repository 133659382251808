/* src/styles/theme.css */
:root {
  --header-color: rgba(243, 220, 104, 0.53); /* Light gray */
  --primary-color: #F5F5F0; /* Touch of red */
  --secondary-color: #B2C3DC; /* Deep blue */
  --accent-color: #C24535; /* Touch of red */
  --text-color: #333; /* Dark gray text */
  --light-gray: #f8f8f8;
  --border-gray: #eee;
  --background-color: #F5F5F0;
  --carousal-color: var(--primary-color);
}

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
  background-color: var(--background-color);
}

.App {
  text-align: center;
  max-width: 960px; /* Limit container width */
  margin: 0 auto; /* Center the content */
  padding: 20px;
}

header {
  background-color: var(--header-color); /* Light golden header */
  color: white;
  padding: 20px 40px;
}

h1 {
  margin-bottom: 0; /* Reduce spacing */
}

section {
  margin-bottom: 30px;
}

.about, .contact {
  background-color: #fffde7; /* Very light golden background */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.gallery {
  /* Style your image gallery here */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.gallery img {
  /* Style individual gallery images */
  width: 200px;
  height: 200px;
  object-fit: cover;
  margin: 10px;
}

footer {
  background-color: var(--header-color);
  color: var(--text-color);
  text-align: center;
  padding: 10px 0;
}

.header-container {
  display: flex;
  align-items: center; /* Vertically align logo and nav */
  justify-content: space-between; /* Space logo and nav apart */
}

.logo {
  height: 100px; /* Adjust logo height as needed */
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex; /* Arrange links horizontally */
}

nav li {
  margin-left: 20px; /* Space between links */
}

nav a {
  text-decoration: none; /* Remove default link underline */
  color: var(--text-color); /* Set link color */
  font-weight: bold; /* Make links bold */
  padding: 10px 15px; /* Add padding to links */
  border-radius: 5px; /* Add rounded corners */
  transition: background-color 0.3s ease; /* Smooth background transition */
}

nav a:hover {
  background-color: var( --secondary-color);
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex-grow: 1;
}

.collections {
  background-color: var(--primary-color);
  /*padding: 40px 20px;*/
}

.collections h2 { /* Target the h2 within .collections */
  text-align: center; /* Center the heading */
  margin-bottom: 30px; /* Add margin below the heading */
  color: var(--text-color);
}

.collections-container {
  display: flex;
  gap: 30px; /* Add spacing between categories and carousel */
}

.categories {
  width: 250px;
  padding: 20px;
  border-radius: 8px; /* Add rounded corners to categories */
  background-color: white; /* White background for categories */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for categories */
  transition: width 0.3s ease-in-out;
}

.categories.collapsed {
  width: 60px;
}

.categories.collapsed h3,
.categories.collapsed li {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.categories h3 {
  color: var(--text-color); /* Match heading color */
  margin-top: 0; /* Reset default margin */
}

.categories ul {
  list-style: none;
  padding: 0;
}

.categories li {
  cursor: pointer;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  color: var(--text-color);
}

.categories li:hover {
  background-color: var(--light-gray);
}

.categories li.active {
  background-color: var(--secondary-color);
  color: white; /* White text for better contrast */
}

.carousel-container {
  flex: 1;
  padding: 20px;
  background-color: var(--carousal-color);
  border-radius: 8px; /* Add rounded corners to carousel */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for carousel */
}

.carousel-container img {
  width: 100%;
  height: 600px;
  object-fit: scale-down;
}

.privacy {
  padding: 40px 20px; /* Adjust padding as needed */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.6; /* Improve readability */
  color: #333; /* Dark gray text */
}

.privacy h1 {
  font-size: 1.8em;
  margin-bottom: 1em;
  color: var(--text-color); /* Assuming your primary color is defined */
}

.privacy p {
  margin-bottom: 1em;
}

.privacy strong {
  font-weight: 600;
}

.privacy ul {
  list-style-type: disc;
  margin-left: 40px;
  margin-bottom: 1em;
}

.privacy li {
  margin-bottom: 0.5em;
}

/* Optional: Style for links within the privacy policy */
.privacy a {
  color: var(--secondary-color); /* Assuming your secondary color is defined */
  text-decoration: none;
}

.privacy a:hover {
  text-decoration: underline;
}

.about-section {
  padding: 40px 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.6;
  color: var(--text-color);
}

.about-header {
  color: #C24535;
}

.about-content {
  max-width: 800px; /* Adjust as needed */
  margin: 0 auto;
}

.about-section h2 {
  font-size: 2.5em;
  margin-bottom: 0.5em;
}

.about-section h1 {
  font-size: 1.8em;
  margin-bottom: 1em;
  /*color: var(--text-color); !* Assuming your primary color is defined *!*/
}

.about-section p {
  margin-bottom: 1.2em;
}

.visit-us {
  background-color: #f8f8f8;
  border: 1px solid #eee;
  padding: 20px;
  border-radius: 5px;
}

.visit-us address {
  margin-top: 10px;
}

.contact-us {
  background-color: var(--primary-color);
  padding: 40px 20px; /* Add padding to the section */
}

.contact-us h2 {
  text-align: center;
  margin-bottom: 30px;
  color: var(--text-color);
}

.contact-us .visit-us {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px; /* Add some space between addresses */
}

.contact-us address {
  margin-bottom: 20px;
  text-align: left; /* Align address text to the left */
  flex: 1 1 300px; /* Each address takes at least 300px and grows to fill available space */
}

.home-section {
  text-align: left; /* Align text to the left */
  padding: 40px 20px;
}

.home-header {
  color: var(--accent-color); /* Use your accent color */
  margin-bottom: 20px;
}

.home-content {
  display: flex; /* Use flexbox for layout */
  gap: 30px; /* Space between image and text */
  align-items: center; /* Vertically align content */
}

.home-content > div { /* Target direct children of .home-content */
  flex: 1; /* Each section takes equal width */
}

.home-image {
  max-width: 100%; /* Ensure image doesn't overflow */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px; /* Add rounded corners to the image */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

.home-text h2 {
  font-size: 2.5em;
  margin-bottom: 1em;
  color: var(--text-color);
}

.home-text p {
  font-size: 1.1em; /* Slightly larger font size for better readability */
  line-height: 1.6;
  margin-bottom: 1.5em; /* Add more spacing between paragraphs */
}

.cta-button { /* Style a call-to-action button */
  display: inline-block;
  padding: 12px 24px;
  background-color: var(--text-color);
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: var(--secondary-color);
}

.account-section {
  display: flex;
  /*justify-content: center;*/
  align-items: center;
  flex-direction: column;
  padding: 20px; /* Adjust padding */
  margin: 0; /* Remove default margin */
}

.container {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.section-title {
  font-size: 24px;
  margin: 10px 0;
  color: #333;
}

.instruction-text {
  margin-bottom: 20px;
  color: #555;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-group {
  width: 100%;
  margin-bottom: 15px;
}

.form-input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 16px;
  box-sizing: border-box; /* Ensure padding is included in width */
}

.account-section .cta-button {
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
  width: 100%;
}

.cta-button {
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #2980b9;
}

.delete-button {
  background-color: #e74c3c;
}

.delete-button:hover {
  background-color: #c0392b;
}

.login-buttons {
  margin-top: 15px; /* Add some spacing above the buttons */
}

.google-login-form {
  display: flex; /* Center the Google button */
  justify-content: center; /* Center the button horizontally */
}

.google-login-button {
  background-color: #db4437;
  color: white;
  border: none;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%; /* Make button full width */
}

.google-login-button .google-icon {
  margin-right: 10px;
}

.google-login-button:hover {
  background-color: #c0392b;
}

.or-text {
  margin: 15px 0;
  color: #555;
}


/* Media Query for Responsiveness (adjust breakpoint as needed) */
@media (max-width: 768px) {
  .home-content {
    flex-direction : column; /* Stack content vertically on smaller screens */
  }

  .home-image {
    margin-bottom : 20px; /* Add spacing below image on mobile */
  }

  .collections-container {
    flex-direction: column; /* Stack categories above carousel */
  }

  .categories {
    width: 100%; /* Categories take full width */
    margin-bottom: 20px; /* Add spacing below categories */
  }

  .contact-us .visit-us {
    flex-direction: column; /* Stack addresses vertically */
    gap: 10px; /* Reduce gap on smaller screens */
  }

  .contact-us address {
    flex-basis: 100%; /* Each address takes full width */
  }
}